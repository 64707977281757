import * as React from "react"

export default function DivorceCostCalculator() {
    const [selectedState, setSelectedState] = React.useState("")
    const [hasChildren, setHasChildren] = React.useState(false)

    const divorceDotComCost = 1999

    const stateData = {
        Alabama: { withKids: 26010, withoutKids: 16452 },
        Alaska: { withKids: 25935, withoutKids: 16227 },
        Arizona: { withKids: 25739, withoutKids: 16181 },
        Arkansas: { withKids: 20127, withoutKids: 12481 },
        California: { withKids: 36836, withoutKids: 23454 },
        Colorado: { withKids: 28407, withoutKids: 17895 },
        Connecticut: { withKids: 31281, withoutKids: 19813 },
        Delaware: { withKids: 31096, withoutKids: 19628 },
        "District of Columbia": { withKids: 25475, withoutKids: 15917 },
        Florida: { withKids: 25873, withoutKids: 16315 },
        Georgia: { withKids: 28777, withoutKids: 18265 },
        Hawaii: { withKids: 22981, withoutKids: 14279 },
        Idaho: { withKids: 20154, withoutKids: 12402 },
        Illinois: { withKids: 25804, withoutKids: 16246 },
        Indiana: { withKids: 22852, withoutKids: 14250 },
        Iowa: { withKids: 22794, withoutKids: 14192 },
        Kansas: { withKids: 20570, withoutKids: 12924 },
        Kentucky: { withKids: 20099, withoutKids: 12453 },
        Louisiana: { withKids: 25510, withoutKids: 15952 },
        Maine: { withKids: 20067, withoutKids: 12421 },
        Maryland: { withKids: 28342, withoutKids: 17830 },
        Massachusetts: { withKids: 31121, withoutKids: 19653 },
        Michigan: { withKids: 25742, withoutKids: 16024 },
        Minnesota: { withKids: 23016, withoutKids: 14414 },
        Mississippi: { withKids: 20684, withoutKids: 13038 },
        Missouri: { withKids: 25731, withoutKids: 15975 },
        Montana: { withKids: 14563, withoutKids: 8829 },
        Nebraska: { withKids: 20105, withoutKids: 12459 },
        Nevada: { withKids: 25808, withoutKids: 16086 },
        "New Hampshire": { withKids: 23240, withoutKids: 14638 },
        "New Jersey": { withKids: 31221, withoutKids: 19753 },
        "New Mexico": { withKids: 15962, withoutKids: 9750 },
        "New York": { withKids: 35497, withoutKids: 22593 },
        "North Carolina": { withKids: 25505, withoutKids: 15797 },
        "North Dakota": { withKids: 20027, withoutKids: 12381 },
        Ohio: { withKids: 23078, withoutKids: 14476 },
        Oklahoma: { withKids: 22844, withoutKids: 14242 },
        Oregon: { withKids: 25736, withoutKids: 16178 },
        Pennsylvania: { withKids: 28331, withoutKids: 17819 },
        "Rhode Island": { withKids: 26075, withoutKids: 16517 },
        "South Carolina": { withKids: 25585, withoutKids: 16027 },
        "South Dakota": { withKids: 21414, withoutKids: 13290 },
        Tennessee: { withKids: 24317, withoutKids: 15089 },
        Texas: { withKids: 32613, withoutKids: 20593 },
        Utah: { withKids: 26861, withoutKids: 16923 },
        Vermont: { withKids: 22678, withoutKids: 14076 },
        Virginia: { withKids: 29567, withoutKids: 18577 },
        Washington: { withKids: 25749, withoutKids: 16191 },
        "West Virginia": { withKids: 20055, withoutKids: 12409 },
        Wisconsin: { withKids: 21509, withoutKids: 13365 },
        Wyoming: { withKids: 22741, withoutKids: 14139 },
    }

    const getTraditionalCost = () => {
        if (!selectedState) return 0
        return hasChildren
            ? stateData[selectedState].withKids
            : stateData[selectedState].withoutKids
    }

    const getSavings = () => {
        return getTraditionalCost() - divorceDotComCost
    }

    return (
        <div
            style={{
                width: "100%",
                maxWidth: "800px",
                margin: "0 auto",
                padding: "20px",
                fontFamily: "ABC Diatype",
            }}
        >
            <div
                style={{
                    background: "white",
                    borderRadius: "12px",
                    padding: "24px",
                    boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                }}
            >
                <h1
                    style={{
                        fontSize: "24px",
                        fontWeight: "600",
                        marginBottom: "24px",
                        color: "#1B1B1B",
                        fontFamily: "ABC Diatype",
                    }}
                >
                    Divorce Cost Comparison Calculator
                </h1>

                <div
                    style={{
                        background: "#E9FFD4",
                        padding: "16px",
                        borderRadius: "8px",
                        marginBottom: "24px",
                        color: "#1B1B1B",
                        fontFamily: "ABC Diatype",
                    }}
                >
                    This calculator provides estimates for informational
                    purposes only and does not constitute legal advice. Actual
                    costs may vary based on individual circumstances.
                </div>

                <div style={{ marginBottom: "24px" }}>
                    <label
                        style={{
                            display: "block",
                            marginBottom: "8px",
                            fontSize: "14px",
                            fontWeight: "500",
                            color: "#1B1B1B",
                            fontFamily: "ABC Diatype",
                        }}
                    >
                        Select Your State
                    </label>
                    <select
                        value={selectedState}
                        onChange={(e) => setSelectedState(e.target.value)}
                        style={{
                            width: "100%",
                            padding: "8px 12px",
                            borderRadius: "6px",
                            border: "1px solid #E2E8F0",
                            fontSize: "14px",
                            fontFamily: "ABC Diatype",
                            color: "#1B1B1B",
                        }}
                    >
                        <option value="">Choose a state</option>
                        {Object.keys(stateData)
                            .sort()
                            .map((state) => (
                                <option key={state} value={state}>
                                    {state}
                                </option>
                            ))}
                    </select>
                </div>

                <div
                    style={{
                        marginBottom: "24px",
                        display: "flex",
                        alignItems: "center",
                        gap: "8px",
                    }}
                >
                    <input
                        type="checkbox"
                        checked={hasChildren}
                        onChange={(e) => setHasChildren(e.target.checked)}
                    />
                    <label
                        style={{
                            fontSize: "14px",
                            fontWeight: "500",
                            color: "#1B1B1B",
                            fontFamily: "ABC Diatype",
                        }}
                    >
                        Do you have children?
                    </label>
                </div>

                {selectedState && (
                    <div>
                        <div
                            style={{
                                display: "grid",
                                gridTemplateColumns:
                                    "repeat(auto-fit, minmax(280px, 1fr))",
                                gap: "20px",
                                marginBottom: "24px",
                            }}
                        >
                            <div
                                style={{
                                    background: "white",
                                    borderRadius: "8px",
                                    padding: "20px",
                                    border: "1px solid #E2E8F0",
                                }}
                            >
                                <h3
                                    style={{
                                        fontSize: "16px",
                                        fontWeight: "600",
                                        marginBottom: "12px",
                                        color: "#1B1B1B",
                                        fontFamily: "ABC Diatype",
                                    }}
                                >
                                    Traditional Divorce
                                </h3>
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "baseline",
                                        marginBottom: "12px",
                                    }}
                                >
                                    <span
                                        style={{
                                            fontSize: "24px",
                                            fontWeight: "700",
                                            color: "#8A7C00",
                                            fontFamily: "ABC Diatype",
                                        }}
                                    >
                                        ${getTraditionalCost().toLocaleString()}
                                    </span>
                                    <span
                                        style={{
                                            fontSize: "12px",
                                            marginLeft: "4px",
                                            color: "#1B1B1B",
                                            fontFamily: "ABC Diatype",
                                        }}
                                    >
                                        per divorcing couple
                                    </span>
                                </div>
                                <div
                                    style={{
                                        fontSize: "14px",
                                        color: "#1B1B1B",
                                        fontFamily: "ABC Diatype",
                                    }}
                                >
                                    Includes:
                                    <ul
                                        style={{
                                            listStyle: "disc",
                                            marginLeft: "20px",
                                            marginTop: "8px",
                                        }}
                                    >
                                        <li>Attorney representation</li>
                                        <li>Legal consultation</li>
                                        <li>Document preparation</li>
                                        <li>Court appearances</li>
                                        <li>Negotiations</li>
                                        <li>Extended legal support</li>
                                    </ul>
                                </div>
                            </div>

                            <div
                                style={{
                                    background: "white",
                                    borderRadius: "8px",
                                    padding: "20px",
                                    border: "1px solid #E2E8F0",
                                }}
                            >
                                <h3
                                    style={{
                                        fontSize: "16px",
                                        fontWeight: "600",
                                        marginBottom: "12px",
                                        color: "#1B1B1B",
                                        fontFamily: "ABC Diatype",
                                    }}
                                >
                                    Divorce.com
                                </h3>
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "baseline",
                                        marginBottom: "12px",
                                    }}
                                >
                                    <span
                                        style={{
                                            fontSize: "24px",
                                            fontWeight: "700",
                                            color: "#2DC334",
                                            fontFamily: "ABC Diatype",
                                        }}
                                    >
                                        ${divorceDotComCost.toLocaleString()}
                                    </span>
                                    <span
                                        style={{
                                            fontSize: "12px",
                                            marginLeft: "4px",
                                            color: "#1B1B1B",
                                            fontFamily: "ABC Diatype",
                                        }}
                                    >
                                        per divorcing couple
                                    </span>
                                </div>
                                <div
                                    style={{
                                        fontSize: "14px",
                                        color: "#1B1B1B",
                                        fontFamily: "ABC Diatype",
                                    }}
                                >
                                    Includes:
                                    <ul
                                        style={{
                                            listStyle: "disc",
                                            marginLeft: "20px",
                                            marginTop: "8px",
                                        }}
                                    >
                                        <li>Document preparation</li>
                                        <li>Filing assistance</li>
                                        <li>Mediation sessions</li>
                                        <li>Basic legal information</li>
                                        <li>Online support</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div
                            style={{
                                background: "#E9FFD4",
                                borderRadius: "8px",
                                padding: "20px",
                                marginBottom: "24px",
                            }}
                        >
                            <h3
                                style={{
                                    fontSize: "16px",
                                    fontWeight: "600",
                                    marginBottom: "8px",
                                    color: "#1B1B1B",
                                    fontFamily: "ABC Diatype",
                                }}
                            >
                                Total amount you could save
                            </h3>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "baseline",
                                }}
                            >
                                <span
                                    style={{
                                        fontSize: "24px",
                                        fontWeight: "700",
                                        color: "#2DC334",
                                        fontFamily: "ABC Diatype",
                                    }}
                                >
                                    ${getSavings().toLocaleString()}
                                </span>
                                <span
                                    style={{
                                        fontSize: "12px",
                                        marginLeft: "4px",
                                        color: "#1B1B1B",
                                        fontFamily: "ABC Diatype",
                                    }}
                                >
                                    per divorcing couple
                                </span>
                            </div>
                        </div>

                        <div
                            style={{
                                fontSize: "14px",
                                color: "#1B1B1B",
                                fontFamily: "ABC Diatype",
                            }}
                        >
                            <p
                                style={{
                                    fontWeight: "500",
                                    marginBottom: "8px",
                                }}
                            >
                                Important Note:
                            </p>
                            <p>
                                Our packages are designed for couples who can
                                work together cooperatively and have relatively
                                straightforward divorce needs. For complex legal
                                situations, contested divorces, or presence of
                                domestic violence, traditional legal
                                representation may be more appropriate.
                            </p>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}
